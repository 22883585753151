import * as Sentry from "@sentry/browser";

const railsEnv = document.querySelector('meta[name="rails-env"]')?.content;

if (railsEnv === 'production') {
  const userIdMeta = document.querySelector('meta[name="user-id"]');
  const accountIdMeta = document.querySelector('meta[name="account-id"]');
  const userId = userIdMeta.content;
  const accountId = accountIdMeta.content;
  const userName = document.querySelector('meta[name="user-name"]')?.content;
  const userEmail = document.querySelector('meta[name="user-email"]')?.content;
  const accountName = document.querySelector('meta[name="account-name"]')?.content;
  const userHash = document.querySelector('meta[name="user-hash"]')?.content;

  Sentry.init({
    dsn: "https://e7a3707ada462de35d38ba6db92fdab9@o4508514958442496.ingest.us.sentry.io/4508515028041728",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/precision\.co/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setUser({
    id: userId,
    email: userEmail,
    name: userName,
    accountId: accountId,
    accountName: accountName,
  });
}
