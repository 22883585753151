import { Controller } from "@hotwired/stimulus"
import { initFlowbite } from 'flowbite'

export default class extends Controller {
  static targets = ["searchInput", "table", "pagination"]

  connect() {
    // Set initial value from URL params if any
    const urlParams = new URLSearchParams(window.location.search)
    this.searchInputTarget.value = urlParams.get('query') || ''
    
    // Handle pagination clicks
    this.paginationTarget.addEventListener('click', (event) => {
      if (event.target.tagName === 'A') {
        event.preventDefault()
        this.fetchPage(event.target.href)
      }
    })
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const searchTerm = this.searchInputTarget.value
      const currentUrl = new URL(window.location)
      
      if (searchTerm) {
        currentUrl.searchParams.set('query', searchTerm)
        currentUrl.searchParams.set('page', '1')
      } else {
        currentUrl.searchParams.delete('query')
        currentUrl.searchParams.set('page', '1')
      }

      this.fetchPage(currentUrl)
    }, 300)
  }

  fetchPage(url) {
    // Update URL without reloading page
    window.history.pushState({}, '', url)
    
    fetch(url, {
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        this.tableTarget.innerHTML = data.users
        this.paginationTarget.innerHTML = data.pagination
        // Reinitialize Flowbite modals after content update
        initFlowbite()
      })
  }
} 