import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "panel"]

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  toggle() {
    const isExpanded = this.buttonTarget.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.panelTarget.classList.remove('hidden')

    // Enhanced animation with scale and better timing
    requestAnimationFrame(() => {
      this.panelTarget.classList.add(
        'transition',
        'ease-out',
        'duration-100',
        'opacity-100',
        'translate-y-0',
        'scale-100'
      )
      this.panelTarget.classList.remove(
        'opacity-0',
        'translate-y-1',
        'scale-95'
      )
    })
  }

  close() {
    this.buttonTarget.setAttribute('aria-expanded', 'false')

    // Enhanced animation with scale and better timing
    this.panelTarget.classList.add(
      'transition',
      'ease-in',
      'duration-75',
      'opacity-0',
      'translate-y-1',
      'scale-95'
    )
    this.panelTarget.classList.remove(
      'opacity-100',
      'translate-y-0',
      'scale-100'
    )

    // Hide after animation
    setTimeout(() => {
      this.panelTarget.classList.add('hidden')
    }, 75)
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target) && this.buttonTarget.getAttribute('aria-expanded') === 'true') {
      this.close()
    }
  }
} 