import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  collapseAll() {
    // Find all CBA collapse controllers
    const controllers = this.application.controllers.filter(controller =>
      controller.context.identifier === "cba-collapse"
    )
    controllers.forEach(controller => controller.collapse())
  }

  expandAll() {
    // Find all CBA collapse controllers
    const controllers = this.application.controllers.filter(controller =>
      controller.context.identifier === "cba-collapse"
    )
    controllers.forEach(controller => controller.expand())
  }
} 