import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    this.initializeSortable()
  }

  initializeSortable() {
    const sortable = new Sortable(this.listTarget, {
      draggable: '.metric-item',
      handle: '.metric-handle',
      mirror: {
        constrainDimensions: true,
        appendTo: 'body'
      },
      classes: {
        'source:dragging': 'opacity-50',
        'mirror': ['bg-white', 'shadow-lg', 'rounded-lg']
      }
    })

    sortable.on('sortable:stop', (event) => {
      requestAnimationFrame(() => {
        const positions = Array.from(event.newContainer.children)
          .filter(el => el.classList.contains('metric-item'))
          .map((item, index) => ({
            id: item.dataset.metricId,
            position: index
          }))

        this.updatePositions(positions)
      })
    })
  }

  async updatePositions(positions) {
    try {
      const response = await fetch(`/settings/metric_categories/${this.element.dataset.categoryId}/reorder_metrics`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ positions })
      })

      const data = await response.json()

      if (!response.ok || !data.success) {
        throw new Error('Failed to update positions')
      }
    } catch (error) {
      console.error('Error updating positions:', error)
    }
  }
} 