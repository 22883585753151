import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite'

export default class extends Controller {
  static targets = ["markWeekClosedModal", "markWeekClosedMessage"]

  connect() {
    this.markWeekClosedModal = new Modal(this.markWeekClosedModalTarget, {
      backdrop: 'static',
      closable: false,
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40'
    });
  }

  showMarkWeekClosedModal() {
    this.markWeekClosedModal.show();
  }

  hideMarkWeekClosedModal() {
    this.markWeekClosedModal.hide();
  }
}