import { Controller } from "@hotwired/stimulus";
import { initFlowbite } from "flowbite";

export default class extends Controller {
  static targets = ["source"];
  static values = {
    successDuration: { type: Number, default: 2000 }
  }

  connect() {
    initFlowbite();
  }

  copy(event) {
    const sourceElement = this.sourceTarget;
    const button = event.currentTarget;

    // Get icon elements from button
    const defaultIcon = button.querySelector("[data-clipboard-default-icon]");
    const successIcon = button.querySelector("[data-clipboard-success-icon]");

    // Get tooltip elements from document using button's tooltip target
    const tooltipId = button.dataset.tooltipTarget;
    const tooltipElement = document.getElementById(tooltipId);
    const tooltip = FlowbiteInstances.getInstance('Tooltip', tooltipId);
    const defaultTooltip = tooltipElement.querySelector("[data-clipboard-default-message]");
    const successTooltip = tooltipElement.querySelector("[data-clipboard-success-message]");

    const showSuccess = () => {
      // Toggle icons in button
      defaultIcon?.classList.add("hidden");
      successIcon?.classList.remove("hidden");

      // Toggle messages in tooltip
      defaultTooltip?.classList.add("hidden");
      successTooltip?.classList.remove("hidden");
      tooltip?.show();

      setTimeout(() => {
        // Reset icons
        defaultIcon?.classList.remove("hidden");
        successIcon?.classList.add("hidden");

        // Reset tooltip
        defaultTooltip?.classList.remove("hidden");
        successTooltip?.classList.add("hidden");
        tooltip?.hide();
      }, this.successDurationValue);
    };

    if (sourceElement) {
      navigator.clipboard.writeText(sourceElement.textContent.trim())
        .then(showSuccess)
        .catch((error) => {
        });
    }
  }
}