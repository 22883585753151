import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  connect() {
    if (this.isTurboPreview()) {
      return;
    }
    this.fetchChartData().then(data => {
      this.initializeChartWithData(data)
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  async fetchChartData() {
    try {
      const response = await fetch('/charts/trailing_twelve_months')
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart data:', error)
    }
  }

  initializeChartWithData(data) {
    const months = this.getLastTwelveCompletedMonths();
    const options = {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: { enabled: false },
        toolbar: { show: false },
        padding: { left: 12, right: 12, top: 0, bottom: 0 },
        zoom: { enabled: false },
        background: '#ffffff',
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
        },
        x: {
          show: true,
          formatter: function (val, opts) {
            return months[opts.dataPointIndex];
          }
        },
        y: {
          formatter: function (value) {
            return '$' + value.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          opacityFrom: 0.3,
          opacityTo: 0.1,
          stops: [0, 100],
          shade: "#0ea5e9",
          gradientToColors: ["#0ea5e9"],
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: [1.5, 2, 0],
        curve: 'smooth',
        dashArray: [5, 0, 0],
      },
      markers: {
        size: [4, 6, 4],
        strokeWidth: [0, 0, 0],
        strokeColors: ['#94a3b8', '#0ea5e9', '#22c55e'],
        shape: ['square', 'circle', 'triangle'],
        hover: {
          size: 6,
          sizeOffset: 3
        },
      },
      grid: {
        show: true,
        borderColor: '#e2e8f0',
        strokeDashArray: 5,
        padding: { left: 5, right: 5, top: 0 },
        position: 'back',
      },
      series: [
        {
          name: "MRR (Prior Year)",
          data: data.prior_year_data.map(Number),
          color: "#94a3b8",
        },
        {
          name: "MRR",
          data: data.current_data.map(Number),
          color: "#0ea5e9",
        },
        {
          name: "Goal",
          data: Array(12).fill(7000),
          color: "#22c55e",
        }
      ],
      xaxis: {
        categories: months,
        labels: {
          show: true,
          style: {
            colors: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          offsetY: 5,
          formatter: function (value) {
            if (value) {
              const [month, year] = value.split(' ');
              return month.substring(0, 3) + ' ' + year.substring(2);
            }
            return '';
          }
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          formatter: function (value) {
            return '$' + value.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
          },
          offsetX: -10,
        },
        title: {
          text: 'Revenue ($)',
          style: {
            color: '#64748b',
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          offsetX: -10,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 10,
        labels: {
          colors: '#64748b',
        },
        markers: {
          width: 8,
          height: 8,
          radius: 10,
        },
      },
    }

    if (typeof ApexCharts !== 'undefined') {
      this.chart = new ApexCharts(this.element, options);
      this.chart.render();
    }
  }

  getLastTwelveCompletedMonths() {
    const today = new Date();
    const months = [];

    for (let i = 1; i <= 12; i++) {
      const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.unshift(this.formatMonth(d));
    }

    return months;
  }

  formatMonth(date) {
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  isTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
}