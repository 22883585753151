// Only run analytics in production
const isProduction = document.querySelector('meta[name="rails-env"]')?.content === 'production';
//const isProduction = true;

// Debounce function to prevent multiple rapid calls
let analyticsTimeout;
const DEBOUNCE_DELAY = 100; // ms

// Handle user identification
document.addEventListener("turbo:load", (event) => {
  if (!isProduction || !window.analytics) {
    return;
  }

  // Clear any pending timeout
  if (analyticsTimeout) {
    clearTimeout(analyticsTimeout);
  }

  analyticsTimeout = setTimeout(() => {
    const userIdMeta = document.querySelector('meta[name="user-id"]');
    const accountIdMeta = document.querySelector('meta[name="account-id"]');

    if (!userIdMeta || !accountIdMeta) {
      return;
    }

    const userId = userIdMeta.content;
    const accountId = accountIdMeta.content;
    const userName = document.querySelector('meta[name="user-name"]')?.content;
    const userEmail = document.querySelector('meta[name="user-email"]')?.content;
    const accountName = document.querySelector('meta[name="account-name"]')?.content;
    const userHash = document.querySelector('meta[name="user-hash"]')?.content;

    // Update Segment
    analytics.page();

    // Re-identify user in Segment
    analytics.identify(userId, {
      name: userName,
      email: userEmail
    }, {
      integrations: {
        Intercom: {
          user_hash: userHash
        }
      }
    });

    // Re-identify group/account in Segment
    analytics.group(accountId, {
      name: accountName
    });

    // Directly update Intercom
    if (window.Intercom) {
      window.Intercom('update', {
        app_id: window.intercomSettings?.app_id,
        user_id: userId,
        name: userName,
        email: userEmail,
        user_hash: userHash,
        company: {
          id: accountId,
          name: accountName
        }
      });
    }
  }, DEBOUNCE_DELAY);
}); 