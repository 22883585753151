import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "options", "button"]
  static values = {
    timezones: Array
  }

  connect() {
    this.filterTimezones = this.filterTimezones.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
    this.originalValue = this.inputTarget.value
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  filterTimezones() {
    const query = this.inputTarget.value.toLowerCase()
    const filteredTimezones = this.timezonesValue.filter(([name, _]) =>
      name.toLowerCase().includes(query)
    )
    this.renderOptions(filteredTimezones)
  }

  renderOptions(timezones) {
    if (timezones.length === 0) {
      this.optionsTarget.innerHTML = `
        <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
          No timezones found
        </li>
      `
      return
    }

    this.optionsTarget.innerHTML = timezones.map(([name, value], index) => `
      <li 
        class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-primary-600 hover:text-white group" 
        role="option"
        data-action="click->timezone-combobox#selectTimezone"
        data-name="${name}"
        data-value="${value}"
      >
        <div class="flex">
          <span class="truncate">${name.split(") ")[1]}</span>
          <span class="ml-2 truncate text-gray-500 group-hover:text-white">${name.split(") ")[0]})</span>
        </div>
      </li>
    `).join('')
  }

  toggleOptions() {
    const isExpanded = this.inputTarget.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      this.closeOptions()
    } else {
      this.openOptions()
    }
  }

  openOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'true')
    this.optionsTarget.classList.remove('hidden')
    this.originalValue = this.inputTarget.value
    this.inputTarget.value = ''
    this.filterTimezones()
    this.inputTarget.focus()
  }

  closeOptions() {
    this.inputTarget.setAttribute('aria-expanded', 'false')
    this.optionsTarget.classList.add('hidden')
    if (!this.inputTarget.value.trim()) {
      this.inputTarget.value = this.originalValue
    }
  }

  selectTimezone(event) {
    const { name, value } = event.currentTarget.dataset
    this.inputTarget.value = name
    this.originalValue = name

    // Update the hidden select field
    const select = this.element.querySelector('select')
    if (select) {
      select.value = value
      select.dispatchEvent(new Event('change'))
    }

    this.closeOptions()
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeOptions()
    }
  }
} 