import { application } from "./application"

import HelloController from "./hello_controller"
import TrailingSixWeeksChartController from "./trailing_six_weeks_chart_controller"
import TrailingTwelveMonthsChartController from "./trailing_twelve_months_chart_controller"
import ScorecardMetricCategoryFormController from "./scorecard_metric_category_form_controller"
import ScorecardInfiniteScrollTableController from "./scorecard_infinite_scroll_table_controller"
import MetricFormController from "./metric_form_controller"
import SwitchAccountController from "./switch_account_controller"
import FlashController from "./flash_controller"
import ImpersonatesController from "./impersonates_controller"
import SettingsTabsController from "./settings_tabs_controller"
import MetricCategoryListController from "./metric_category_list_controller"
import InlineEditController from "./inline_edit_controller"
import MetricCategoryMetricsController from "./metric_category_metrics_controller"
import NorthStarMetricSelectorController from "./north_star_metric_selector_controller"
import PrecisionRadialChartsController from "./precision_radial_charts_controller"
import ScorecardFlyoutController from "./scorecard_flyout_controller"
import ProfileMenuController from "./profile_menu_controller"
import CbaCollapseController from "./cba_collapse_controller"
import CollapseControlsController from "./collapse_controls_controller"
import ClipboardController from "./clipboard_controller"
import MarkWeekClosedController from "./mark_week_closed_controller"
import CustomModalController from "./custom_modal_controller"
import TimezoneComboboxController from "./timezone_combobox_controller"

application.register("hello", HelloController)
application.register("trailing-six-weeks-chart", TrailingSixWeeksChartController)
application.register("trailing-twelve-months-chart", TrailingTwelveMonthsChartController)
application.register("scorecard-metric-category-form", ScorecardMetricCategoryFormController)
application.register("scorecard-infinite-scroll-table", ScorecardInfiniteScrollTableController)
application.register("metric-form", MetricFormController)
application.register("switch-account", SwitchAccountController)
application.register("flash", FlashController)
application.register("impersonates", ImpersonatesController)
application.register("settings-tabs", SettingsTabsController)
application.register("metric-category-list", MetricCategoryListController)
application.register("inline-edit", InlineEditController)
application.register("metric-category-metrics", MetricCategoryMetricsController)
application.register("north-star-metric-selector", NorthStarMetricSelectorController)
application.register("precision-radial-charts", PrecisionRadialChartsController)
application.register("scorecard-flyout", ScorecardFlyoutController)
application.register("profile-menu", ProfileMenuController)
application.register("cba-collapse", CbaCollapseController)
application.register("collapse-controls", CollapseControlsController)
application.register("clipboard", ClipboardController)
application.register("mark-week-closed", MarkWeekClosedController)
application.register("custom-modal", CustomModalController)
application.register("timezone-combobox", TimezoneComboboxController)
