import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "formulaSection",
    "formulaInput",
    "dataType",
    "autocomplete",
    "validationMessage",
    "operatorButtons",
    "metricSelector",
    "aggregationType",
    "priorPeriodMetricSection",
    "priorPeriodMetricSelect",
    "numberType",
    "pacingType"
  ]

  connect() {
    this.initializeState()
    this.setupEventListeners()
    this.updateFormState()
  }

  // Initialize controller state
  initializeState() {
    this.isInitialLoad = true
    this.typingBuffer = ''
    this.allowedChars = new Set(['(', ')', '+', '-', '*', '/', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' '])

    // Store initial select values and options
    this.initialValues = {
      dataType: this.dataTypeTarget.value,
      numberType: this.numberTypeTarget.value,
      aggregationType: this.aggregationTypeTarget.value,
      priorPeriodMetric: this.priorPeriodMetricSelectTarget.value,
      pacingType: this.pacingTypeTarget.value
    }

    // Store original options for selects that need filtering
    this.originalOptions = {
      numberType: this.getSelectOptions(this.numberTypeTarget),
      aggregationType: JSON.parse(this.aggregationTypeTarget.dataset.aggregationTypes),
      pacingType: JSON.parse(this.pacingTypeTarget.dataset.pacingTypes).map(([text, value]) => ({
        text,
        value
      }))
    }
  }

  // Setup event listeners
  setupEventListeners() {
    this.formulaInputTarget.addEventListener('input', () => this.validateFormula())
  }

  // Update initial form state
  updateFormState() {
    // If it's prior period growth and has a source metric, filter number types first
    if (this.dataTypeTarget.value === "prior_period_growth" && this.initialValues.priorPeriodMetric) {
      const selectedOption = this.priorPeriodMetricSelectTarget.options[this.priorPeriodMetricSelectTarget.selectedIndex]
      const sourceNumberType = selectedOption?.dataset.numberType
      this.filterNumberTypeOptions(sourceNumberType)
    }

    this.dataTypeChanged()
    this.toggleFormulaBuilder()
    this.hideAutocomplete()
  }

  // Helper to get select options
  getSelectOptions(select) {
    return Array.from(select.options).map(opt => ({
      value: opt.value,
      text: opt.text,
      dataset: { ...opt.dataset }
    }))
  }

  // Helper to update select options while preserving initial value
  updateSelectOptions(select, options, initialValue) {
    select.innerHTML = options
      .map(opt => `<option value="${opt.value}">${opt.text}</option>`)
      .join('')

    // Try to restore initial value if it exists in filtered options
    if (this.isInitialLoad && initialValue) {
      const hasInitialValue = Array.from(select.options)
        .some(option => option.value === initialValue)

      if (hasInitialValue) {
        select.value = initialValue
        return
      }
    }

    // Otherwise set first available option
    if (select.options.length > 0) {
      select.value = select.options[0].value
    }
  }

  // Filter number type options based on prior period metric
  filterNumberTypeOptions(sourceNumberType) {
    const dataType = this.dataTypeTarget.value
    let allowedTypes = []

    if (dataType === "prior_period_growth") {
      // For prior period growth, only show the source metric's number type
      allowedTypes = sourceNumberType ? [sourceNumberType] : this.originalOptions.numberType
      .filter(opt => opt.value !== 'percentage')
      .map(opt => opt.value)
    } else {
      // For other data types, show all options including percentage
      allowedTypes = sourceNumberType ? [sourceNumberType, 'percentage'] : null
    }

    const filteredOptions = allowedTypes
      ? this.originalOptions.numberType.filter(opt => allowedTypes.includes(opt.value))
      : this.originalOptions.numberType

    this.updateSelectOptions(
      this.numberTypeTarget,
      filteredOptions,
      this.initialValues.numberType
    )
  }

  // Filter aggregation type options based on data type and number type
  filterAggregationTypeOptions() {
    const dataType = this.dataTypeTarget.value
    const numberType = this.numberTypeTarget.value

    let filteredOptions = this.originalOptions.aggregationType

    // Remove sum option if number type is percentage
    if (numberType === "percentage") {
      filteredOptions = filteredOptions.filter(([_, value]) => value !== "sum")
    }

    if (dataType === "calculated") {
      filteredOptions = filteredOptions.filter(([_, value]) => value === "calculation")
    } else if (dataType === "prior_period_growth") {
      filteredOptions = filteredOptions.filter(([_, value]) =>
        numberType === "percentage" ? value === "prior_period_growth" : value === "sum"
      )
    } else {
      filteredOptions = filteredOptions.filter(([_, value]) =>
        !["calculation", "prior_period_growth"].includes(value)
      )
    }

    this.updateSelectOptions(
      this.aggregationTypeTarget,
      filteredOptions.map(([label, value]) => ({ value, text: label })),
      this.initialValues.aggregationType
    )
  }

  // Event Handlers
  dataTypeChanged() {
    const isCalculated = this.dataTypeTarget.value === "calculated"
    const isPriorPeriodGrowth = this.dataTypeTarget.value === "prior_period_growth"

    this.formulaSectionTarget.classList.toggle("hidden", !isCalculated)
    this.priorPeriodMetricSectionTarget.classList.toggle("hidden", !isPriorPeriodGrowth)

    if (isPriorPeriodGrowth) {
      // Show all number types except percentage
      const selectedOption = this.priorPeriodMetricSelectTarget.options[this.priorPeriodMetricSelectTarget.selectedIndex]
      const sourceNumberType = selectedOption?.dataset.numberType
      this.filterNumberTypeOptions(sourceNumberType ? sourceNumberType : null)
    } else {
      // For other data types, show all number types
      this.priorPeriodMetricSelectTarget.value = ""
      this.filterNumberTypeOptions(null)
    }

    this.filterAggregationTypeOptions()
    this.filterPacingTypeOptions()
  }

  handlePriorPeriodMetricChange(event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const sourceNumberType = selectedOption?.dataset.numberType

    this.filterNumberTypeOptions(sourceNumberType)
    this.filterAggregationTypeOptions()
  }

  numberTypeChanged() {
    this.filterAggregationTypeOptions()
    this.filterPacingTypeOptions()
  }

  toggleFormulaBuilder() {
    const isCalculated = this.dataTypeTarget.value === "calculated"
    this.formulaSectionTarget.classList.toggle("hidden", !isCalculated)

    // Only clear formula if it's not the initial page load
    if (isCalculated && !this.isInitialLoad) {
      this.formulaInputTarget.value = ""
    }

    // Set isInitialLoad to false after first run
    this.isInitialLoad = false
  }

  insertOperator(event) {
    const operator = event.currentTarget.textContent.trim()
    const input = this.formulaInputTarget
    const cursorPosition = input.selectionStart
    const currentValue = input.value.replace(/\n/g, '')

    // Insert operator at cursor position
    const newValue = currentValue.slice(0, cursorPosition) + operator + currentValue.slice(cursorPosition)
    input.value = newValue

    // Move cursor after the inserted operator
    const newCursorPosition = cursorPosition + operator.length
    input.setSelectionRange(newCursorPosition, newCursorPosition)
    input.focus()

    // Validate after insertion
    this.validateFormula()
  }

  insertMetric(event) {
    const select = event.currentTarget
    const selectedOption = select.options[select.selectedIndex]

    if (selectedOption.value) {
      const metricName = selectedOption.text.toLowerCase()
      const shortId = selectedOption.value
      this.insertMetricToFormula(metricName, shortId)
      select.selectedIndex = 0
      this.formulaInputTarget.focus()
    }
  }

  insertMetricToFormula(name, shortId) {
    const input = this.formulaInputTarget
    const cursorPosition = input.selectionStart
    const currentValue = input.value
    const metricText = `{${name}:${shortId}}`

    // If we're in the middle of searching, remove the search braces and typing buffer
    if (this.typingBuffer) {
      // Remove the typing buffer and its braces from the current position
      const valueWithoutSearch = currentValue.slice(0, cursorPosition - (this.typingBuffer.length + 2)) +
        currentValue.slice(cursorPosition)

      // Insert the new metric at the original cursor position
      const newValue = valueWithoutSearch.slice(0, cursorPosition - (this.typingBuffer.length + 2)) +
        metricText +
        valueWithoutSearch.slice(cursorPosition - (this.typingBuffer.length + 2))

      input.value = newValue
      const newCursorPosition = cursorPosition - (this.typingBuffer.length + 2) + metricText.length
      input.setSelectionRange(newCursorPosition, newCursorPosition)
    } else {
      // Direct insertion at cursor (no typing buffer)
      const newValue = currentValue.slice(0, cursorPosition) +
        metricText +
        currentValue.slice(cursorPosition)
      input.value = newValue
      const newCursorPosition = cursorPosition + metricText.length
      input.setSelectionRange(newCursorPosition, newCursorPosition)
    }

    this.typingBuffer = ''
    this.hideAutocomplete()
    input.focus()

    // Validate after insertion
    this.validateFormula()
  }

  clearFormula() {
    this.formulaInputTarget.value = ""
    this.formulaInputTarget.focus()
    this.validateFormula()
    this.showValidationMessage('')
  }

  handleKeydown(event) {
    // Add arrow key handling at the start of the method
    if (this.typingBuffer && !this.autocompleteTarget.classList.contains('hidden')) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault()

        const options = Array.from(this.autocompleteTarget.children)
        const currentHighlight = this.autocompleteTarget.querySelector('.bg-gray-100')
        const currentIndex = currentHighlight ? options.indexOf(currentHighlight) : -1

        // Remove current highlight
        currentHighlight?.classList.remove('bg-gray-100')

        // Calculate new index
        let newIndex
        if (event.key === 'ArrowDown') {
          newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0
        } else {
          newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1
        }

        // Add highlight to new option
        options[newIndex]?.classList.add('bg-gray-100')
        return
      }
    }

    // Handle Tab or Enter for autocomplete selection
    if ((event.key === 'Tab' || event.key === 'Enter') && this.typingBuffer && !this.autocompleteTarget.classList.contains('hidden')) {
      event.preventDefault()
      const highlightedOption = this.autocompleteTarget.querySelector('.bg-gray-100')
      if (highlightedOption && highlightedOption.dataset.name) {
        const { name, shortId } = highlightedOption.dataset
        this.insertMetricToFormula(name, shortId)
      }
      // If no highlighted option or no matches, do nothing
      this.validateFormula()
      return
    }

    // Allow any key combinations with ctrl, cmd, or alt
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return
    }

    // Handle backspace
    if (event.key === 'Backspace') {
      const input = event.target
      const selectionStart = input.selectionStart
      const selectionEnd = input.selectionEnd
      const value = input.value
      const hasSelection = selectionStart !== selectionEnd

      // If we're in typing buffer mode
      if (this.typingBuffer.length > 0) {
        event.preventDefault()
        this.typingBuffer = this.typingBuffer.slice(0, -1)

        // Get everything before the typing buffer
        const cursorPosition = input.selectionStart
        const startPos = cursorPosition - (this.typingBuffer.length + 3)
        const beforeBuffer = value.slice(0, startPos)
        const afterBuffer = value.slice(cursorPosition)
        console.log('beforeBuffer', beforeBuffer)
        console.log('afterBuffer', afterBuffer)

        if (this.typingBuffer.length > 0) {
          // Update with remaining buffer
          input.value = beforeBuffer + `{${this.typingBuffer}}` + afterBuffer
          input.setSelectionRange(startPos + this.typingBuffer.length + 2, startPos + this.typingBuffer.length + 2)
          this.showAutocomplete()
        } else {
          // Remove the empty search
          input.value = beforeBuffer + afterBuffer
          input.setSelectionRange(startPos, startPos)
          this.hideAutocomplete()
        }
        this.validateFormula()
        return
      }

      // Handle selection that includes a closing brace
      if (hasSelection && value.slice(selectionStart, selectionEnd).includes('}')) {
        event.preventDefault()
        const selectedText = value.slice(selectionStart, selectionEnd)
        let textBeforeSelection = value.slice(0, selectionStart)
        let textAfterSelection = value.slice(selectionEnd)

        // If selection contains both braces, just remove the selection
        if (selectedText.includes('{') && selectedText.includes('}')) {
          input.value = textBeforeSelection + textAfterSelection
          input.setSelectionRange(selectionStart, selectionStart)
          this.validateFormula()
          return
        }

        // Otherwise, find partial metric references
        let lastOpenBrace = textBeforeSelection.lastIndexOf('{')
        let firstCloseBrace = textAfterSelection.indexOf('}')

        // If we have an opening brace before the selection
        if (lastOpenBrace !== -1) {
          textBeforeSelection = textBeforeSelection.slice(0, lastOpenBrace)
        }

        // If we have a closing brace after the selection
        if (firstCloseBrace !== -1) {
          textAfterSelection = textAfterSelection.slice(firstCloseBrace + 1)
        }

        input.value = textBeforeSelection + textAfterSelection
        input.setSelectionRange(textBeforeSelection.length, textBeforeSelection.length)
        this.validateFormula()
        return
      }

      // Check if cursor is inside a metric reference
      const beforeCursor = value.slice(0, selectionStart)
      const afterCursor = value.slice(selectionStart)
      const lastOpenBrace = beforeCursor.lastIndexOf('{')
      const nextCloseBrace = afterCursor.indexOf('}')

      if (lastOpenBrace !== -1 && nextCloseBrace !== -1) {
        const closeBracePosition = selectionStart + nextCloseBrace
        const potentialMetric = value.slice(lastOpenBrace, closeBracePosition + 1)

        // Verify this is a valid metric reference format {name:id}
        if (potentialMetric.includes(':')) {
          event.preventDefault()
          input.value = value.slice(0, lastOpenBrace) + value.slice(closeBracePosition + 1)
          input.setSelectionRange(lastOpenBrace, lastOpenBrace)
          this.validateFormula()
          return
        }
      }

      // Handle single closing brace deletion
      if (!hasSelection && value[selectionStart - 1] === '}') {
        event.preventDefault()
        let openBracePos = value.lastIndexOf('{', selectionStart)
        if (openBracePos !== -1) {
          input.value = value.slice(0, openBracePos) + value.slice(selectionStart)
          input.setSelectionRange(openBracePos, openBracePos)
          this.validateFormula()
        }
        return
      }
    }

    // Handle escape key
    if (event.key === 'Escape') {
      if (this.typingBuffer) {
        // Remove the search braces when escaping
        const valueWithoutSearch = this.formulaInputTarget.value.slice(0, -(this.typingBuffer.length + 2))
        this.formulaInputTarget.value = valueWithoutSearch
      }
      this.hideAutocomplete()
      this.typingBuffer = ''
      return
    }

    // Allow special keys without preventing default
    if (event.key.length > 1 || event.key === 'Tab' || ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(event.key)) && !this.typingBuffer)) return

    // Only prevent default for non-allowed characters
    if (!this.allowedChars.has(event.key) && !/[a-zA-Z\s]/.test(event.key)) {
      event.preventDefault()
      return
    }

    // Handle potential metric name typing
    if (/[a-zA-Z\s]/.test(event.key) || [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(event.key))) {
      const input = this.formulaInputTarget
      const cursorPosition = input.selectionStart

      if (!this.typingBuffer) {
        if (event.key === ' ') {
          // Allow space to pass through when not in buffer mode
          return
        }
        event.preventDefault()
        // Start new search with braces at cursor position
        this.typingBuffer = event.key.toLowerCase()
        const newValue = input.value.slice(0, cursorPosition) +
          `{${this.typingBuffer}}` +
          input.value.slice(cursorPosition)

        input.value = newValue
        const newCursorPosition = cursorPosition + this.typingBuffer.length + 2
        input.setSelectionRange(newCursorPosition, newCursorPosition)
      } else {
        event.preventDefault()
        // Update existing search at cursor position
        this.typingBuffer += event.key.toLowerCase()
        const searchStartPos = cursorPosition - (this.typingBuffer.length + 1)
        const newValue = input.value.slice(0, searchStartPos) +
          `{${this.typingBuffer}}` +
          input.value.slice(cursorPosition + 1)

        input.value = newValue
        const newCursorPosition = searchStartPos + this.typingBuffer.length + 2
        input.setSelectionRange(newCursorPosition, newCursorPosition)
      }
      this.showAutocomplete()
      return
    }

    // Handle allowed operators and numbers
    if (this.allowedChars.has(event.key)) {
      if (this.typingBuffer) {
        event.preventDefault()

        // Check if we have any matching metrics
        const metrics = Array.from(document.getElementById('metric_selector').options)
          .filter(option => option.value)
          .map(option => ({
            name: option.text.toLowerCase(),
            shortId: option.value
          }))
          .filter(metric => metric.name.includes(this.typingBuffer))

        if (metrics.length > 0) {
          // Insert the first matching metric
          const firstMatch = metrics[0]
          this.insertMetricToFormula(firstMatch.name, firstMatch.shortId)

          // Then insert the operator/number
          const input = this.formulaInputTarget
          const cursorPosition = input.selectionStart
          input.value = input.value.slice(0, cursorPosition) + event.key + input.value.slice(cursorPosition)
          input.setSelectionRange(cursorPosition + 1, cursorPosition + 1)
        } else {
          // No matches, just remove the search and insert the character
          const valueWithoutSearch = this.formulaInputTarget.value.slice(0, -(this.typingBuffer.length + 2))
          this.formulaInputTarget.value = valueWithoutSearch + event.key
          this.typingBuffer = ''
          this.hideAutocomplete()
        }
      }
      this.validateFormula()
      return
    }
  }

  showAutocomplete() {
    if (this.typingBuffer.length > 0) {
      const metrics = Array.from(document.getElementById('metric_selector').options)
        .filter(option => option.value)
        .map(option => ({
          name: option.text.toLowerCase(),
          shortId: option.value
        }))
        .filter(metric => metric.name.includes(this.typingBuffer))
        .slice(0, 5)

      if (metrics.length > 0) {
        const html = metrics.map((metric, index) => `
          <div class="p-2 cursor-pointer ${index === 0 ? 'bg-gray-100' : ''} hover:bg-gray-100 group"
               data-action="click->metric-form#selectAutocomplete"
               data-name="${metric.name}"
               data-short-id="${metric.shortId}">
            <div class="flex items-center justify-between">
              <span>${metric.name}</span>
              <div class="flex gap-2 text-xs text-gray-400 hidden group-[.bg-gray-100]:flex">
                <span class="px-1.5 py-0.5 border border-gray-200 rounded">Tab</span>
                <span class="px-1.5 py-0.5 border border-gray-200 rounded">↵</span>
              </div>
            </div>
          </div>
        `).join('')
        this.autocompleteTarget.innerHTML = html
      } else {
        this.autocompleteTarget.innerHTML = `
          <div class="p-2 text-gray-500 italic">
            No metrics found
          </div>
        `
      }
      this.autocompleteTarget.classList.remove('hidden')
    }
  }

  hideAutocomplete() {
    this.autocompleteTarget.innerHTML = ''
    this.autocompleteTarget.classList.add('hidden')
  }

  selectAutocomplete(event) {
    const { name, shortId } = event.currentTarget.dataset
    this.insertMetricToFormula(name, shortId)
  }

  validateFormula() {
    const formula = this.formulaInputTarget.value.trim()
    let errors = []
    let isValid = true

    // Check for empty formula when data type is calculated
    if (this.dataTypeTarget.value === "calculated") {
      if (!formula) {
        errors.push('Formula is required for calculated metrics')
        isValid = false
      }

      // Check parentheses matching and content
      let parenCount = 0
      const parenStack = []
      const parenContents = []
      let currentContent = ''

      for (let i = 0; i < formula.length; i++) {
        const char = formula[i]
        if (char === '(') {
          parenCount++
          parenStack.push(i)
          currentContent = ''
        } else if (char === ')') {
          parenCount--
          if (parenStack.length > 0) {
            const startIndex = parenStack.pop()
            parenContents.push(formula.slice(startIndex + 1, i))
          }
          if (parenCount < 0) break
        } else if (parenStack.length > 0) {
          currentContent += char
        }
      }

      // Check for unmatched parentheses
      if (parenCount !== 0) {
        errors.push('Unmatched parentheses')
        isValid = false
      }

      // Validate contents of each parenthetical expression
      parenContents.forEach(content => {
        const trimmedContent = content.trim()
        if (!trimmedContent) {
          errors.push('Empty parentheses are not allowed')
          isValid = false
        } else {
          // Check for valid expression inside parentheses
          // Should contain at least two metrics and one operator
          const metrics = trimmedContent.match(/{[^}]+}/g) || []
          const operators = trimmedContent.match(/[\+\-\*\/]/g) || []

          if (metrics.length < 2 || operators.length < 1) {
            errors.push('Parentheses must contain at least two metrics and one operator')
            isValid = false
          }
        }
      })

      // Check for hanging operators
      const operators = new Set(['+', '-', '*', '/', '.'])
      const lastChar = formula[formula.length - 1]
      if (operators.has(lastChar)) {
        errors.push('Formula cannot end with an operator or decimal point')
        isValid = false
      }

      // Check for consecutive operators
      const consecutiveOperatorsRegex = /[\+\-\*\/]{2,}|\.{2,}/
      if (consecutiveOperatorsRegex.test(formula)) {
        errors.push('Cannot have consecutive operators or decimal points')
        isValid = false
      }

      // Check for valid decimal numbers
      const numbers = formula.split(/[\+\-\*\/\(\)]/);
      const invalidDecimal = numbers.some(num => {
        const trimmed = num.trim()
        return trimmed.split('.').length > 2
      })
      if (invalidDecimal) {
        errors.push('Invalid decimal number format')
        isValid = false
      }
    }

    // Show validation message and update submit button
    this.showValidationMessage(errors.join('. '))

    // Toggle submit button state
    const submitButton = this.element.querySelector('input[type="submit"]')
    if (submitButton) {
      submitButton.disabled = !isValid
      submitButton.classList.toggle('opacity-50', !isValid)
      submitButton.classList.toggle('cursor-not-allowed', !isValid)
    }

    return isValid
  }

  showValidationMessage(message) {
    this.validationMessageTarget.textContent = message
    this.validationMessageTarget.classList.toggle('hidden', !message)
    this.validationMessageTarget.classList.toggle('text-red-500', !!message)
  }

  // Add method to filter pacing type options
  filterPacingTypeOptions() {
    const aggregationType = this.aggregationTypeTarget.value
    const dataType = this.dataTypeTarget.value
    const numberType = this.numberTypeTarget.value
    let allowedTypes = []

    // Handle prior period growth percentage case first
    if (dataType === 'prior_period_growth' && numberType === 'percentage') {
      allowedTypes = ['linear_ppgp', 'direct_comparison']
    } else if (aggregationType === 'latest') {
      allowedTypes = ['linear_growth', 'direct_comparison']
    } else if (aggregationType === 'sum') {
      allowedTypes = ['linear_total', 'direct_comparison']
    } else if (aggregationType === 'calculation') {
      // Show all options except linear_ppgp for calculated metrics
      allowedTypes = ['linear_growth', 'direct_comparison', 'linear_total']
    }

    const filteredOptions = this.originalOptions.pacingType
      .filter(opt => allowedTypes.includes(opt.value))

    if (this.initialValues.pacingType) {
      const isValidInitialValue = allowedTypes.includes(this.initialValues.pacingType)

      if (isValidInitialValue) {
        this.updateSelectOptions(
          this.pacingTypeTarget,
          filteredOptions,
          this.initialValues.pacingType
        )
        return
      }
    }

    this.updateSelectOptions(
      this.pacingTypeTarget,
      filteredOptions
    )
  }

  // Add aggregationTypeChanged method
  aggregationTypeChanged() {
    this.filterPacingTypeOptions()
  }
}
