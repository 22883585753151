import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  connect() {
    if (this.isTurboPreview()) {
      return;
    }

    this.fetchChartData().then(data => {
      this.initializeCharts(data);
    });
  }

  async fetchChartData() {
    try {
      const response = await fetch('/charts/precision_radial_data');
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  }

  getColorForValue(value) {
    if (value >= 90) return '#22C55E' // green-500
    if (value >= 80) return '#16A34A' // green-600
    if (value >= 70) return '#F97316' // orange-500
    if (value >= 60) return '#EA580C' // orange-600
    return '#EF4444' // red-500
  }

  formatMetricValue(value, metric = null) {
    if (value === null || value === undefined) return 'N/A';
    if (value === Infinity || value === "Infinity" || value === "∞") return "∞";

    // Convert string values to numbers
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Handle percentage formatting
    if (metric && (metric.data_type === 'percentage' ||
      (metric.data_type === 'calculated' && metric.number_type === 'percentage'))) {
      return numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + '%';
    }

    // Handle currency formatting
    if (metric && metric.number_type === 'currency') {
      return '$' + numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }

    // For non-percentage and non-currency values, only show decimals if they exist
    const hasDecimals = numericValue % 1 !== 0;
    let formattedValue = numericValue.toLocaleString(undefined, {
      minimumFractionDigits: hasDecimals ? 2 : 0,
      maximumFractionDigits: hasDecimals ? 2 : 0
    });

    // Add plus/minus signs for growth metrics
    if (metric && this.isGrowthMetric(metric)) {
      formattedValue = numericValue >= 0 ? `+${formattedValue}` : formattedValue;
    }

    return formattedValue;
  }

  isGrowthMetric(metric) {
    return (metric.pacing_type === "linear_growth" && metric.aggregation_type === "latest") ||
      (metric.pacing_type === "linear_ppgp" && metric.aggregation_type === "prior_period_growth");
  }

  buildTooltipText(nsm, originalValue) {
    if (!nsm) return '';

    const isGrowth = this.isGrowthMetric(nsm.metric);
    const goalText = isGrowth ? "weekly growth goal" : "weekly goal";

    // For linear_growth metrics, use the value directly as it's already the growth value
    const value = nsm.value
    const actualValue = isGrowth ? this.formatMetricValue(nsm.performance.actual_value_used, nsm.metric) : this.formatMetricValue(nsm.performance.actual_value_used, nsm.metric);
    const goalValue = this.formatMetricValue(nsm.performance.goal_value_used, nsm.metric);

    // For direct comparison metrics
    if (nsm.metric.pacing_type === "direct_comparison") {
      return `<div class="text-xs mt-1">
        <!-- ★ ${nsm.name}: ${value}<br> -->
        📊 <strong>${actualValue}</strong> vs. ${goalText} of ${goalValue}<br>
        ${nsm.performance.prior_month_actual && nsm.performance.prior_month_goal ? `📈 Last Month: <strong>${this.formatMetricValue(nsm.performance.prior_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.prior_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
        ${nsm.performance.current_month_actual && nsm.performance.current_month_goal ? `📆 Month To Date: <strong>${this.formatMetricValue(nsm.performance.current_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.current_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
      </div>`;
    }

    // For prior period growth metrics
    if (nsm.metric.data_type === "prior_period_growth") {
      const plusOrMinus = nsm.metric.more_is_better ? "+" : "-";
      return `<div class="text-xs mt-1">
        <!-- ★ ${nsm.name}: ${value}<br> -->
        📊 ${plusOrMinus}<strong>${actualValue}</strong> vs. ${goalText} of ${plusOrMinus}${goalValue}<br>
        ${nsm.performance.prior_month_actual && nsm.performance.prior_month_goal ? `📈 Last Month: <strong>${this.formatMetricValue(nsm.performance.prior_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.prior_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
        ${nsm.performance.current_month_actual && nsm.performance.current_month_goal ? `📆 Month To Date: <strong>${this.formatMetricValue(nsm.performance.current_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.current_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
      </div>`;
    }

    // Default format (including linear growth)
    const rawMetric = { ...nsm.metric, pacing_type: 'direct_comparison' }; // Temporarily change type to avoid +/- formatting
    return `<div class="text-xs mt-1">
      <!-- ★ ${nsm.name}: ${this.formatMetricValue(nsm.value, rawMetric)}<br> -->
      📊 <strong>${actualValue}</strong> vs. ${goalText} of ${goalValue}<br>
      ${nsm.performance.prior_month_actual && nsm.performance.prior_month_goal ? `📈 Last Month: <strong>${this.formatMetricValue(nsm.performance.prior_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.prior_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
      ${nsm.performance.current_month_actual && nsm.performance.current_month_goal ? `📆 Month To Date: <strong>${this.formatMetricValue(nsm.performance.current_month_actual, nsm.metric).replace(/^[+-]/, '')}</strong> vs. goal of ${this.formatMetricValue(nsm.performance.current_month_goal, nsm.metric).replace(/^[+-]/, '')}<br>` : ''}
    </div>`;
  }

  initializeCharts(data) {
    const chartConfigs = [
      {
        id: 'chart1',
        series: data.revenue_funnel.series,
        originalSeries: data.revenue_funnel.series.slice(),
        labels: data.revenue_funnel.labels,
        northStarMetrics: data.revenue_funnel.north_star_metrics
      },
      {
        id: 'chart2',
        series: data.delivery_funnel.series,
        originalSeries: data.delivery_funnel.series.slice(),
        labels: data.delivery_funnel.labels,
        northStarMetrics: data.delivery_funnel.north_star_metrics
      },
      {
        id: 'chart3',
        series: data.leverage_funnel.series,
        originalSeries: data.leverage_funnel.series.slice(),
        labels: data.leverage_funnel.labels,
        northStarMetrics: data.leverage_funnel.north_star_metrics
      }
    ];

    // Check for infinity conditions and mark them
    chartConfigs.forEach(config => {
      config.series = config.series.map((value, index) => {
        const nsm = config.northStarMetrics[index];
        // Check if this should be infinity (goal is 0 and more_is_better is true)
        if (nsm?.performance?.goal_value_used === 0 && nsm?.metric?.more_is_better === true) {
          return "∞";
        }
        return value;
      });
      config.originalSeries = config.series.slice();
    });

    // Convert infinity values to 100 for display in the chart
    chartConfigs.forEach(config => {
      config.series = config.series.map(value =>
        value === "∞" ? 100 : value
      );
    });

    this.charts = [];
    chartConfigs.forEach(config => {
      const options = {
        series: config.series,
        chart: {
          height: 390,
          type: 'radialBar',
          background: 'transparent',
          fontFamily: 'Inter, sans-serif',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            track: {
              background: '#e2e8f0',
              strokeWidth: '97%',
              margin: 5,
              dropShadow: {
                enabled: false,
              }
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              offsetX: -8,
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              formatter: function (seriesName, opts) {
                const index = opts.seriesIndex;
                const originalValue = config.originalSeries[index];
                const displayValue = originalValue === "∞" ? "100" : opts.w.globals.series[index];
                let label = `${seriesName}: ${displayValue}%`;
                return label;
              },
            },
          }
        },
        responsive: [{
          breakpoint: 1024,
          options: {
            plotOptions: {
              radialBar: {
                barLabels: {
                  offsetX: -4,
                }
              }
            }
          }
        }],
        tooltip: {
          enabled: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Inter, sans-serif',
          },
          custom: ({ seriesIndex, w }) => {
            const originalValue = config.originalSeries[seriesIndex];
            const name = w.globals.labels[seriesIndex];
            const nsm = config.northStarMetrics[seriesIndex];
            const color = w.globals.colors[seriesIndex];

            const displayValue = originalValue === "∞" ? "100" : `${originalValue}`;

            return `<div class="p-4" style="background: #ffffff; border-radius: 6px; box-shadow: 2px 2px 3px rgba(0,0,0,0.2);">
              <div class="font-semibold" style="color: ${color}">${nsm.name || name}</div>
              <div class="text-sm font-bold">${displayValue}% Weekly Attainment</div>
              ${this.buildTooltipText(nsm, originalValue)}
            </div>`;
          }
        },
        colors: config.series.map(value => {
          if (value >= 90) return '#22C55E' // green-500
          if (value >= 80) return '#16A34A' // green-600
          if (value >= 70) return '#F97316' // orange-500
          if (value >= 60) return '#EA580C' // orange-600
          return '#EF4444' // red-500
        }),
        labels: config.labels,
      };

      const chart = new ApexCharts(document.getElementById(config.id), options);
      chart.render();
      this.charts.push(chart);
    });
  }

  disconnect() {
    if (this.charts) {
      this.charts.forEach(chart => chart.destroy());
    }
  }

  isTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
} 