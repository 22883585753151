import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display", "input", "editMode"]
  static values = { url: String }

  connect() {
    this.originalValue = this.inputTarget.value
    this.createMeasureSpan()
    this.updateInputWidth()
  }

  disconnect() {
    if (this.measureSpan) {
      this.measureSpan.remove()
    }
  }

  createMeasureSpan() {
    this.measureSpan = document.createElement('span')
    this.measureSpan.style.visibility = 'hidden'
    this.measureSpan.style.position = 'absolute'
    this.measureSpan.style.whiteSpace = 'pre'
    // Copy the font styles from the input to ensure accurate measurement
    const inputStyles = window.getComputedStyle(this.inputTarget)
    this.measureSpan.style.font = inputStyles.font
    this.measureSpan.style.padding = inputStyles.padding
    document.body.appendChild(this.measureSpan)
  }

  startEditing() {
    this.displayTarget.parentElement.classList.add("hidden")
    this.editModeTarget.classList.remove("hidden")
    this.editModeTarget.classList.add("flex")
    this.updateInputWidth()
    this.inputTarget.focus()
    this.inputTarget.select()
  }

  updateInputWidth() {
    this.measureSpan.textContent = this.inputTarget.value
    // Add extra padding for cursor and some breathing room
    const width = this.measureSpan.offsetWidth + 50
    this.inputTarget.style.width = `${Math.max(width, 200)}px` // Minimum width of 200px
  }

  save() {
    const value = this.inputTarget.value.trim()
    if (value === "") return

    const token = document.querySelector('meta[name="csrf-token"]').content

    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        metric_category: {
          name: value
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.displayTarget.textContent = value
          this.originalValue = value
          this.stopEditing()
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  cancel() {
    this.inputTarget.value = this.originalValue
    this.stopEditing()
  }

  stopEditing() {
    this.displayTarget.parentElement.classList.remove("hidden")
    this.editModeTarget.classList.add("hidden")
    this.editModeTarget.classList.remove("flex")
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.save()
    } else if (event.key === "Escape") {
      event.preventDefault()
      this.cancel()
    }
  }
} 