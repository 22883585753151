import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  onChange(event) {
    const selectedMetric = event.target.value;
    const form = this.element.closest('form');
    const accountNorthMetricId = form.dataset.northStarMetricSelectorAccountNorthMetricIdValue;

    if (selectedMetric && accountNorthMetricId) {
      form.action = `/settings/account_north_star_metrics/${accountNorthMetricId}`;
      this.setFormMethod(form, 'PATCH');
    } else if (selectedMetric) {
      form.action = '/settings/account_north_star_metrics';
      this.setFormMethod(form, 'POST');
    } else if (accountNorthMetricId) {
      form.action = `/settings/account_north_star_metrics/${accountNorthMetricId}`;
      this.setFormMethod(form, 'DELETE');
    } else {
      return;
    }

    // Add CSRF token to form before submission
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'authenticity_token';
    tokenInput.value = token;
    form.appendChild(tokenInput);

    form.submit();
  }

  setFormMethod(form, method) {
    form.method = 'POST'; // Always set to POST as the actual HTTP method
    let methodInput = form.querySelector('input[name="_method"]');
    if (!methodInput) {
      methodInput = document.createElement('input');
      methodInput.type = 'hidden';
      methodInput.name = '_method';
      form.appendChild(methodInput);
    }
    methodInput.value = method;
  }
} 